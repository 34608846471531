import { useCallback, useEffect, useState } from "react";
import * as S from "./styles.js";
import axios from "axios";
import { validarCPF } from "../../utils/validadorCPF.js";
import { getDebtToken } from "../../services/auth.js";

const Register = ({ setParentValue }) => {

  const [data, setData] = useState({
    id: "",
    name: "",
    email: "",
    cpf: "",
    projectInspector: false,
    phoneNumber: "",
    position: "",
    sector: "",
    userRoleId: "65768be54a468136f718394e", //Atualizado se for selecionado outro
    cityHallId: "6504e3f4138b9834d74dd109", //Atualizado se for selecionado outro
    disabled: false,
  });

  const [selectData, setSelectData] = useState([{
    id: "",
    name: ""
  }])

  const [selectCityData, setSelectCityData] = useState([{
    id: "",
    name: ""
  }])

  const [id, setId] = useState("");

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : (name === "cpf" ? value.replace(/\D/g, '') : value),
    }));
  };

  const addUser = useCallback(async () => {

    let selectElement = document.querySelector("select[name='userRoleId']");
    let arrselectElement = Array.from(selectElement.children);
    let userRoleId = "";
    arrselectElement.forEach((option) => {
      if (option.value === data.userRoleId) {
        userRoleId = option.id;
      }
    })

    let selectCityElement = document.querySelector("select[name='cityHallId']");
    let arrselectCityElement = Array.from(selectCityElement.children);
    let cityHallId = "";
    arrselectCityElement.forEach((option) => {
      if (option.value === data.cityHallId) {
        cityHallId = option.id;
      }
    })

    let addData = {
      id: null,
      name: data.name,
      email: data.email,
      cpf: Number(data?.cpf?.replace(/[^a-zA-Z0-9]/g, '')),
      projectInspector: data.projectInspector,
      disabled: false,
      phoneNumber: data.phoneNumber,
      position: data.position,
      sector: data.sector,
      userRoleId: userRoleId ? userRoleId : data.userRoleId,
      cityHallId: cityHallId ? cityHallId : data.cityHallId,
    }

    let dataApiData = {
      email: data.email,
      password: process.env.REACT_APP_DEFAULT_PASSWORD,
      apps: {
        "debt": true,
        "docs": false,
        "patr": false,
        "lgpd": false,
        "paper": false,
        "pfpj": false,
        "simn": false
      }
    }

    try {
      const [debtResponse, dataApiResponse] = await Promise.all([
        axios.post(`${process.env.REACT_APP_DEBT_API}user/new`, addData, {
          headers: {
            'Authorization': `Bearer ${getDebtToken()}`,
            'Content-Type': 'application/json'
          }
        }),
        axios.post(`${process.env.REACT_APP_DATA_API}user`, dataApiData, {
          headers: {
            Authorization: `${process.env.REACT_APP_DATA_AUTH}`,
          }
        })
      ]);
      
      if(debtResponse.status === 200 && dataApiResponse.status === 200) {
        alert("Usuário criado com sucesso!");
        setParentValue("listagem");
      }

    } catch (error) {
      if (error.status === 400) {
        alert(error.response.data.message);
      }
    }

  }, [data, setParentValue])

  const editUser = useCallback(async () => {

    let selectElement = document.querySelector("select[name='userRoleId']");
    let arrselectElement = Array.from(selectElement.children);
    let userRoleId = "";
    arrselectElement.forEach((option) => {
      if (option.value === data.userRoleId) {
        userRoleId = option.id;
      }
    })

    let selectCityElement = document.querySelector("select[name='cityHallId']");
    let arrselectCityElement = Array.from(selectCityElement.children);
    let cityHallId = "";
    arrselectCityElement.forEach((option) => {
      if (option.value === data.cityHallId) {
        cityHallId = option.id;
      }
    })

    let editData = {
      id: id,
      name: data.name,
      email: data.email,
      cpf: data.cpf,
      projectInspector: data.projectInspector,
      disabled: data.disabled,
      phoneNumber: data.phoneNumber,
      position: data.position,
      sector: data.sector,
      userRoleId: userRoleId ? userRoleId : data.userRoleId,
      cityHallId: cityHallId ? cityHallId : data.cityHallId,
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_DEBT_API}admin/saveUser`, editData, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        alert("Editado com sucesso!");
        setParentValue("listagem");
      }
    } catch (error) {
      console.log(error);
    }
  }, [data, id, setParentValue])

  const getData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DEBT_API}admin/getUser/${id}`, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      });

      const dataAPI = response.data;
      setData(dataAPI);

    } catch (error) {
      console.log(error);
    }
  }, [id])

  const getUserRoles = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DEBT_API}admin/getUserRoles?countUser=true`, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      });
      const data = response.data;
      setSelectData(data);
    } catch (error) {
      console.log(error);
    }
  }, [])

  const getCityHalls = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DEBT_API}open/cityhall/getAll`, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      });
      const data = response.data;
      const reducedData = [];
      data.forEach((row) => {
        reducedData.push({ id: row.id, name: row.legalName })
      });
      setSelectCityData(reducedData);
    } catch (error) {
      console.log(error);
    }
  }, [])

  const checkIsValidInput = (element) => {
    if (element.name === "cpf") {
      let valid = validarCPF(element.value);
      valid ? element.parentElement.classList.remove("error") : element.parentElement.classList.add("error");
    }
    if (element.name === "name") {
      let valid = element.value.length >= 1;
      valid ? element.parentElement.classList.remove("error") : element.parentElement.classList.add("error");
    }
    if (element.name === "email") {
      let valid = element.value.length >= 1;
      valid ? element.parentElement.classList.remove("error") : element.parentElement.classList.add("error");
    }
  };

  //Verifica se existe um ID setado para atualizar valores dos inputs
  useEffect(() => {
    let userId = localStorage.getItem("userId");
    if (userId) setId(userId);
    localStorage.removeItem("userId");
    if (id) {
      getData();
    }
  }, [getData, id])

  useEffect(() => {
    getUserRoles();
    getCityHalls();
  }, [getUserRoles, getCityHalls])

  return (
    <>
      <S.Header>
        <S.Content>
          <S.Breadcrumb><button onClick={() => setParentValue("listagem")}>Home</button> <span>&gt;</span> <span>Usuários</span> <span>&gt;</span></S.Breadcrumb>
          {id === "" && <S.Title>Criar</S.Title>}
          {id && <S.Title>Editar</S.Title>}
        </S.Content>
      </S.Header>
      <S.Card>

        <S.Head>
          <h3>Dados do Usuário</h3>
        </S.Head>
        <S.Form>
          <div className="row-4">
            <label>
              Nível de acesso
              <select id="userRoleId" name="userRoleId"
                value={data.userRoleId}
                onChange={handleChange}>
                {selectData && selectData.map((e) => {
                  return <option key={e.id} id={e.id} value={e.id}>{e.name}</option>
                })}
              </select>
            </label>
          </div>
          <div className="row-2">
            <label>
              Prefeitura
              <select id="cityHallId" name="cityHallId"
                value={data.cityHallId}
                onChange={handleChange}>
                {selectCityData && selectCityData.map((e) => {
                  return <option key={e.id} id={e.id} value={e.id}>{e.name}</option>
                })}
              </select>
            </label>
          </div>
          <div className="row-2">
            <label>
              Fiscal do projeto
            </label>
            <label className="switch" htmlFor="projectInspector">
              <input
                id="projectInspector"
                type="checkbox"
                name="projectInspector"
                checked={data.projectInspector}
                onChange={handleChange}
              >
              </input>
              <div className="slider round"></div>
            </label>
          </div>
          {id && <div className="row-2">
            <label>
              Usuário inativo?
            </label>
            <label className="switch" htmlFor="disabled">
              <input
                id="disabled"
                type="checkbox"
                name="disabled"
                checked={data.disabled}
                onChange={handleChange}
              >
              </input>
              <div className="slider round"></div>
            </label>
          </div>}
          {!id && <div className="row-2"></div>}
          <div className="row-4">
            <label>
              CPF *
              <input
                type="text"
                name="cpf"
                value={data.cpf}
                onChange={handleChange}
                required
                maxLength={14}
                onBlur={(e) => { checkIsValidInput(e.target) }}
              ></input>
              <small>CPF Inválido</small>
            </label>
          </div>
          <div className="row-8">
            <label className="fullwidth">
              Nome Completo *
              <input
                type="text"
                name="name"
                value={data.name}
                required
                onChange={handleChange}
                onBlur={(e) => { checkIsValidInput(e.target) }}
              ></input>
              <small>Nome Completo é obrigatório</small>
            </label>
          </div>
          <div className="row-4">
            <label>
              Telefone
              <input
                type="text"
                maxLength={11}
                name="phoneNumber"
                value={data.phoneNumber}
                onChange={handleChange}
                onBlur={(e) => { checkIsValidInput(e.target) }}
              ></input>
            </label>
          </div>
          <div className="row-8">
            <label className="fullwidth">
              E-mail *
              <input
                required
                type="email"
                name="email"
                value={data.email}
                onChange={handleChange}
              ></input>
              <small>E-mail é obrigatório</small>
            </label>
          </div>
          <div className="row-6">
            <label>
              Cargo/Função
              <input
                type="text"
                name="position"
                value={data.position}
                onChange={handleChange}
              ></input>
            </label>
          </div>
          <div className="row-6">
            <label className="fullwidth">
              Setor
              <input
                type="text"
                name="sector"
                value={data.sector}
                onChange={handleChange}
              ></input>
            </label>
          </div>
          <S.Actions className="row-12">
            {id === "" && <S.BtnSave onClick={() => { addUser() }}>Salvar usuário</S.BtnSave>}
            {id && <S.BtnSave onClick={() => { editUser() }}>Editar usuário</S.BtnSave>}
            <S.BtnCancel onClick={() => { setParentValue("listagem") }}>Cancelar</S.BtnCancel>
          </S.Actions>
        </S.Form>
      </S.Card>
    </>
  )
}

export default Register;